import React from 'react';
import myCv from '../assets/docs/supun.pdf';

const HomeHeader = () => {
  return (
    <header className="header">
      <div className="container">
        <ul className="social-icons pt-3">
          <li className="social-item">
            <a
              className="social-link text-light"
              href="https://www.facebook.com/supun.dharmasiri/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ti-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-item">
            <a
              className="social-link text-light"
              href="https://twitter.com/supundharmasiri"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ti-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-item">
            <a
              className="social-link text-light"
              href="https://www.linkedin.com/in/supun-dharmasiri-48402314a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ti-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-item">
            <a
              className="social-link text-light"
              href="https://www.instagram.com/supundharmasiri/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ti-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-item">
            <a
              className="social-link text-light"
              href="https://github.com/supundharmasiri"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ti-github" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <div className="header-content">
          <h4 className="header-subtitle">Hello, I am</h4>
          <h1 className="header-title">Supun Dharmasiri</h1>
          <h6 className="header-mono">Associate Tech Lead</h6>
          <a
            className="btn btn-outline-light btn-rounded"
            href={myCv}
            download="CSVFile.pdf"
          >
            <i className="ti-download " /> Download Resume
          </a>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
