import React from 'react';
import { HomeHeader, NavBar, AboutMe } from '../../components';

const Home = () => {
  return (
    <div>
      <HomeHeader />
      <NavBar />
      <AboutMe />
    </div>
  );
};

export default Home;
