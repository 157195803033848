import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWindowRestore,
  faLaptopCode,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonMailto } from './';
import myCv from '../assets/docs/supun.pdf';

const AboutMe = () => {
  return (
    <div className="container-fluid">
      <div id="about" className="row about-section">
        <div className="col-lg-4 about-card">
          <h3 className="font-weight-light">Who am I ?</h3>
          <span className="line mb-5"></span>
          <h5 className="mb-3">Associate Tech Lead</h5>
          <p className="mt-20">
            I am a Associate Tech Lead having experience of working with
            cutting-edge technologies like React Native, ReactJS and NodeJs. I
            also have experience of working with cloud platforms like AWS.
          </p>
          <a
            className="btn btn-outline-danger"
            href={myCv}
            download="CSVFile.pdf"
          >
            <i className="ti-download " /> Download My CV
          </a>
        </div>
        <div className="col-lg-4 about-card">
          <h3 className="font-weight-light">Personal Info</h3>
          <span className="line mb-5"></span>
          <ul className="mt40 info list-unstyled">
            <li>
              <span>Birthdate</span> : 12/18/1993
            </li>
            <li>
              <span>Email</span> :{' '}
              <ButtonMailto
                label="rgsdharmasiri@gmail.com"
                mailto="mailto:rgsdharmasiri@gmail.com"
              />
            </li>
            <li>
              <span>Phone</span> :{' '}
              <a href="tel:+94716942270"> +94 (71) 694-2270 </a>
            </li>
            <li>
              <span>Address</span> : Janitha, Makala Addara Watta, Imaduwa
            </li>
          </ul>
          <ul className="social-icons pt-3">
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.facebook.com/supun.dharmasiri/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ti-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://twitter.com/supundharmasiri"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ti-twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.linkedin.com/in/supun-dharmasiri-48402314a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ti-linkedin" aria-hidden="true"></i>
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.instagram.com/supundharmasiri/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ti-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://github.com/supundharmasiri"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ti-github" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 about-card">
          <h3 className="font-weight-light">My Expertise</h3>
          <span className="line mb-5"></span>
          <div className="row">
            <div className="col-1 text-danger pt-1">
              <FontAwesomeIcon className="icon-lg" icon={faWindowRestore} />
            </div>
            <div className="col-10 ml-auto mr-2">
              <h6>Web Development</h6>
              <p className="subtitle">React</p>
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-1 text-danger pt-1">
              <i className="ti-mobile icon-lg"></i>
            </div>
            <div className="col-10 ml-auto mr-2">
              <h6>Mobile Development</h6>
              <p className="subtitle">React Native</p>
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-1 text-danger pt-1">
              <FontAwesomeIcon className="icon-lg" icon={faLaptopCode} />
            </div>
            <div className="col-10 ml-auto mr-2">
              <h6>Backend Development</h6>
              <p className="subtitle">NodeJs</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
